import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledTextInput = styled(TextInput)`
  &::before {
    ${fonts.paragraphLarge};
    & {
      font-weight: bold;
    }
  }

  margin-bottom: ${spacing(2)};
`;
