import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import {
  ContentAlignment,
  Orientation,
} from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { useFirstEligibleStartDateFromToday } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import BooleanRadioWithUnknownInput from 'components/BooleanWithUnknownRadioInput';
import CostInput from 'components/CostInput';
import { NameInput } from 'components/NameInput/styles';
import QuestionField from 'components/QuestionField';
import { IconWrapper } from 'components/StatusPanel/styles';
import {
  PageTitle,
  trackFormTextFieldFocus,
  trackRadioButtonClick,
} from 'helpers/eventTracking';
import { getAnalyticsDescriptionForInput } from 'helpers/getAnalyticsDescriptionForInput';
import getPetIdForInput from 'helpers/getPetId';
import {
  INPUT_REGEX_PET_NAME,
  INPUT_REGEX_PET_NAME_SPECIAL_CHARS,
} from 'helpers/inputRegexes';
import { usePetNameReplacerWithDefault } from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_DOG } from 'helpers/referenceDataConstants';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { useTouchAssumption } from 'state/formData/assumptionsAgreement';
import { initialPet, Pet, PetsDetails, PetWithKey } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { Question } from 'types/forms';
import CatBreedQuestions from './CatBreedQuestions';
import DogBreedQuestions from './DogBreedQuestions';
import {
  showEligibilityQuestions,
  showGeneralPetQuestions,
  showPetBreedQuestions,
  showPetGoodHealthQuestions,
} from './gradualRevealHelpers';
import useAboutYourPetQuestions from './questions';
import {
  DividerWithMargins,
  InfoContainer,
  QuestionFieldWithMarginTop,
  RadioInputWithLargePadding,
  StyledPanel,
  StyledQuestionField,
  StyledRichTextWithModal,
  StyledTermsBlock,
} from './styles';
import { usePetTypeOptions } from './usePetTypeOptions';

type AboutYourPetFormData = {
  csPetAboutYourPetMainQuestionsV2: {
    about_your_pet_terms: string;
  };
  csPetAboutYourPetCatQuestionsV2: {
    eligibility_conditions_agreement: {
      eligibility_panel_text: string;
    };
  };
  csPetAboutYourPetDogQuestionsV2: {
    eligibility_conditions_agreement: {
      eligibility_panel_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYourPetMainQuestionsV2 {
      about_your_pet_terms
    }
    csPetAboutYourPetCatQuestionsV2 {
      eligibility_conditions_agreement {
        eligibility_panel_text
      }
    }
    csPetAboutYourPetDogQuestionsV2 {
      eligibility_conditions_agreement {
        eligibility_panel_text
      }
    }
  }
`;

type AboutYourPetFormProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<{ petsDetails: PetsDetails }, string | undefined>;
    getWarning: FieldFunction<{ petsDetails: PetsDetails }, string | undefined>;
    showValidation: FieldFunction<{ petsDetails: PetsDetails }, void>;
    resetValidation: FieldFunction<{ petsDetails: PetsDetails }, void>;
  };
};

const AboutYourPetForm: React.FC<AboutYourPetFormProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, getWarning, showValidation, resetValidation },
}) => {
  const {
    csPetAboutYourPetMainQuestionsV2: { about_your_pet_terms },
    csPetAboutYourPetCatQuestionsV2,
    csPetAboutYourPetDogQuestionsV2,
  } = useStaticQuery<AboutYourPetFormData>(query);

  const questions = useAboutYourPetQuestions(petDetails);
  const [, updatePolicyDetails] = usePolicyDetails();

  const getIdForInput = getPetIdForInput(index);

  const gendersRefData = useReferenceData('genders');
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const petNameReplacer = usePetNameReplacerWithDefault(petDetails.petName);

  const petIsDog = petDetails.petType === petType_DOG;

  const [firstEligibleStartDate] = useFirstEligibleStartDateFromToday();

  const eligibilityPanelText = petNameReplacer(
    petIsDog
      ? csPetAboutYourPetDogQuestionsV2.eligibility_conditions_agreement
          .eligibility_panel_text
      : csPetAboutYourPetCatQuestionsV2.eligibility_conditions_agreement
          .eligibility_panel_text
  );

  const eligibilityConditionsAgreementQuestion = (pet: Pet): Question =>
    pet.petType === petType_DOG
      ? questions.dogEligibilityConditionsAgreement
      : questions.catEligibilityConditionsAgreement;

  const touchAssumption = useTouchAssumption();

  return (
    <div id={`petDetails[${index}]`}>
      <StyledTermsBlock>{about_your_pet_terms}</StyledTermsBlock>
      <QuestionField
        question={questions.petName}
        errorText={getError('petsDetails', ['petName', index])}>
        <NameInput
          id={getIdForInput('petName')}
          value={petDetails.petName}
          placeholder={questions.petName.placeholder}
          onChange={(e) => {
            if (
              !e.target.value.endsWith('  ') &&
              (e.target.value.match(INPUT_REGEX_PET_NAME) ||
                // Users from aggs could have special characters in their pet's name.
                // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
                (!petDetails.petName.match(INPUT_REGEX_PET_NAME) &&
                  e.target.value.match(INPUT_REGEX_PET_NAME_SPECIAL_CHARS)))
            ) {
              updatePetDetails({
                petName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => showValidation('petsDetails', ['petName', index])}
          onFocus={trackFormTextFieldFocus(
            getAnalyticsDescriptionForInput(index, 'Pet name')
          )}
          maxLength={45}
        />
      </QuestionField>
      <QuestionField
        question={questions.petType}
        errorText={getError('petsDetails', ['petType', index])}>
        <RadioInputWithLargePadding
          id={getIdForInput('petType')}
          value={petDetails.petType}
          options={usePetTypeOptions()}
          contentAlignmentOverride={ContentAlignment.center}
          onChange={(e) => {
            const { petName } = petDetails;
            // We do this so that if generic pet questions are answered before petType is selected
            // the inputs are unchanged, but if the petType is changed after initial selection all
            // inputs below the petType question are cleared.
            const petDetailsToUpdate =
              petDetails.petType === ''
                ? {
                    petType: e.target.value,
                  }
                : {
                    ...initialPet,
                    petType: e.target.value,
                    petName,
                  };
            updatePetDetails(petDetailsToUpdate);
            resetValidation('petsDetails', ['eligibilityConditionsAgreement', index]);
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput(index, 'Pet type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {showEligibilityQuestions(petDetails) && (
        <>
          <StyledPanel data-cy="eligibilityConditionsInfoPanel">
            <InfoContainer>
              <IconWrapper>
                <Icon size="large" name="info" color={colors.core01} />
              </IconWrapper>
              <StyledRichTextWithModal
                html={eligibilityPanelText}
                pageTitle={PageTitle.AboutYourPet}
              />
            </InfoContainer>
            <StyledQuestionField
              question={eligibilityConditionsAgreementQuestion(petDetails)}
              errorText={getError('petsDetails', [
                'eligibilityConditionsAgreement',
                index,
              ])}>
              <BooleanRadioInput
                id={getIdForInput('eligibilityConditionsAgreement')}
                analyticsDescription={getAnalyticsDescriptionForInput(
                  index,
                  'Eligibility conditions agreement'
                )}
                value={petDetails.eligibilityConditionsAgreement}
                onChange={(value) => {
                  updatePetDetails({
                    eligibilityConditionsAgreement: value,
                  });
                  showValidation('petsDetails', [
                    'eligibilityConditionsAgreement',
                    index,
                  ]);
                }}
                orientationOverride={Orientation.mobileVertical}
              />
            </StyledQuestionField>
          </StyledPanel>
        </>
      )}
      {showPetGoodHealthQuestions(petDetails) && (
        <>
          <QuestionFieldWithMarginTop
            question={questions.petInGoodHealth}
            errorText={getError('petsDetails', ['petInGoodHealth', index])}
            warningText={getWarning('petsDetails', ['petInGoodHealth', index])}>
            <BooleanRadioInput
              id={getIdForInput('petInGoodHealth')}
              analyticsDescription={getAnalyticsDescriptionForInput(
                index,
                'Pet is in good health'
              )}
              value={petDetails.petInGoodHealth}
              onChange={(value) => {
                touchAssumption('good_health');
                updatePetDetails({
                  petInGoodHealth: value,
                });
                showValidation('petsDetails', ['petInGoodHealth', index]);
              }}
            />
          </QuestionFieldWithMarginTop>
        </>
      )}

      {showGeneralPetQuestions(petDetails) && (
        <>
          <DividerWithMargins />
          <QuestionField
            question={questions.petGender}
            errorText={getError('petsDetails', ['petGender', index])}>
            <RadioInput
              id={getIdForInput('petGender')}
              value={petDetails.petGender}
              options={gendersRefData?.genders ?? []}
              orientationOverride={Orientation.horizontal}
              onChange={(e) => {
                updatePetDetails({
                  petGender: e.target.value,
                });
                trackRadioButtonClick(
                  getAnalyticsDescriptionForInput(index, 'Pet gender'),
                  e.target.value
                );
              }}
            />
          </QuestionField>
          <QuestionField
            question={questions.petDob}
            errorText={petNameReplacer(getError('petsDetails', ['petDob', index]) ?? '')}
            warningText={petNameReplacer(
              getWarning('petsDetails', ['petDob', index]) ?? ''
            )}
            initiallyShowTooltip>
            <DateInput
              data-cy="pet-dob"
              id={getIdForInput('petDob')}
              value={petDetails.petDob}
              onChange={(value) => updatePetDetails({ petDob: value })}
              onBlur={() => {
                updatePetDetails({
                  petDob: addLeadingZerosToDateValue(petDetails.petDob),
                });
                updatePolicyDetails({
                  coverStartDate: firstEligibleStartDate.toISOString(),
                });
                showValidation('petsDetails', ['petDob', index]);
              }}
              onFocus={trackFormTextFieldFocus(
                getAnalyticsDescriptionForInput(index, 'Pet dob')
              )}
            />
          </QuestionField>
          <QuestionField
            question={questions.petCost}
            errorText={getError('petsDetails', ['petCost', index])}>
            <CostInput
              id={getIdForInput('petCost')}
              value={petDetails.petCost}
              placeholder={questions.petCost.placeholder}
              onCostChange={(value) => {
                updatePetDetails({
                  petCost: value,
                });
              }}
              onBlur={() => showValidation('petsDetails', ['petCost', index])}
              onFocus={trackFormTextFieldFocus(
                getAnalyticsDescriptionForInput(index, 'Pet cost')
              )}
              noCostButtonText={questions.petCost.noCostButtonText}
              onNoCostClicked={() =>
                trackRadioButtonClick(
                  getAnalyticsDescriptionForInput(index, 'Pet cost'),
                  '0'
                )
              }
            />
          </QuestionField>
          <QuestionField
            question={questions.petSpayed}
            errorText={getError('petsDetails', ['petSpayed', index])}>
            <BooleanRadioWithUnknownInput
              id={getIdForInput('petSpayed')}
              analyticsDescription={getAnalyticsDescriptionForInput(
                index,
                'Is pet spayed'
              )}
              value={petDetails.petSpayed}
              onChange={(value) => {
                updatePetDetails({
                  petSpayed: value,
                });
              }}
            />
          </QuestionField>
        </>
      )}
      {showGeneralPetQuestions(petDetails) && !petIsDog && (
        <QuestionField
          question={questions.petChipped}
          errorText={getError('petsDetails', ['petChipped', index])}>
          <BooleanRadioWithUnknownInput
            id={getIdForInput('petChipped')}
            analyticsDescription={getAnalyticsDescriptionForInput(
              index,
              'Is pet chipped'
            )}
            value={petDetails.petChipped}
            onChange={(value) => {
              updatePetDetails({
                petChipped: value,
              });
            }}
          />
        </QuestionField>
      )}
      {showPetBreedQuestions(petDetails) &&
        (petIsDog ? (
          <DogBreedQuestions
            index={index}
            petDetails={petDetails}
            updatePetDetails={updatePetDetails}
            formValidation={{ getError, showValidation }}
            dogBreedReferenceData={dogBreedsRefData}
            pageTitle={PageTitle.AboutYourPet}
          />
        ) : (
          <>
            <CatBreedQuestions
              index={index}
              petDetails={petDetails}
              updatePetDetails={updatePetDetails}
              formValidation={{ getError, showValidation }}
              catBreedReferenceData={catBreedsRefData}
            />
          </>
        ))}
    </div>
  );
};

export default AboutYourPetForm;
