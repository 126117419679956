import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { FormInputErrorProps } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React, { ChangeEventHandler } from 'react';
import { INPUT_REGEX_MONEY_VALUE } from 'helpers/inputRegexes';
import { StyledTextInput } from './styles';

type CostInputProps = {
  value?: number;
  onCostChange: (cost: number | undefined) => void;
  id: string;
  onBlur: () => void;
  onFocus: () => void;
  noCostButtonText: string;
  placeholder?: string;
  onNoCostClicked?: ChangeEventHandler;
} & FormInputErrorProps;

export const CostInput: React.FC<CostInputProps> = ({
  value,
  onCostChange,
  id,
  onBlur,
  onFocus,
  noCostButtonText,
  placeholder,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedBy,
  onNoCostClicked,
}) => (
  <>
    <StyledTextInput
      id={id}
      prefix="£"
      maxLength={6}
      inputMode="numeric"
      value={value?.toLocaleString('en-GB') ?? ''}
      placeholder={placeholder}
      onChange={(e) => {
        const eventValue = e.target.value.replace(/[^0-9]/g, '');
        if (eventValue.match(INPUT_REGEX_MONEY_VALUE)) {
          onCostChange(eventValue ? parseInt(eventValue, 10) : undefined);
        }
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      aria-invalid={ariaInvalid}
      aria-describedby={ariaDescribedBy}
    />
    <RadioInput
      id={`${id}Button`}
      value={value === 0 ? 'noCost' : undefined}
      options={[{ name: noCostButtonText, value: 'noCost' }]}
      onChange={(e) => {
        onCostChange(0);
        if (onNoCostClicked) {
          onNoCostClicked(e);
        }
      }}
    />
  </>
);

export default CostInput;
