import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/JohnLewis/colors';
import styled from 'styled-components';
import { InfoPanel } from 'components/StatusPanel';

export const BreedNameInfoPanel = styled(InfoPanel)`
  margin: ${spacing(-3)} 0 ${spacing(4)};

  && {
    padding: ${spacing(3)};
  }
`;

export const ShadowContainer = styled.div`
  &:focus-within {
    transition: ease 0.2s all;
    padding: ${spacing(2)};
    background-color: ${colors.core02};
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      0 16px 24px -16px rgba(0, 0, 0, 0.16);
  }

  &: focus-within + ${BreedNameInfoPanel} {
    margin: ${spacing(2)} 0 ${spacing(4)};

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(3)};
    `}
  }
`;
