import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  && {
    p.large {
      ${fonts.headingSmall}
    }
    p,
    li {
      ${fonts.paragraphLarge}
    }
  }
`;

export const StyledPanel = styled.div`
  ${fonts.paragraph}
  display: flex;
  flex-direction: column;
  border: solid 1px ${colors.core01};
  padding: ${spacing(3)};
  margin: 0 0 ${spacing(2)};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px ${colors.neutral04};
  padding-bottom: ${spacing(2)};
  ${mediaQuery.tabletPortrait`
    padding-bottom: ${spacing(3)}
  `}
  margin-bottom: ${spacing(2)};
`;

export const StyledQuestionField = styled(QuestionField)`
  width: 100%;
  margin: 0;
`;

export const HeadingWithMargins = styled.h3`
  ${fonts.headingSmall};
  margin: ${spacing(4)} 0 ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(5)};  
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(4)};  
  `}
`;

export const DividerWithMargins = styled(Divider)`
  margin: ${spacing(4)} 0;

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(5)};
  `}

  && {
    display: block;
  }
`;

export const RadioInputWithLargePadding = styled(RadioInput)`
  ${RadioItem} {
    padding: ${spacing(2)} ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      padding: ${spacing(2)} ${spacing(5)}; 
    `}
  }
`;
export const StyledTermsBlock = styled.p`
  ${fonts.paragraphLarge}
  margin-top: -${spacing(2)};
`;

export const QuestionFieldWithMarginTop = styled(QuestionField)`
  margin-top: ${spacing(2)};
  padding-top: ${spacing(2)};
`;
