import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { graphql, useStaticQuery } from 'gatsby';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { CsOption } from 'types/contentStack';

type CsPetTypeOptions = {
  csPetAboutYourPetMainQuestionsV2: {
    pet_type: {
      cat_option: CsOption;
      dog_option: CsOption;
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPetMainQuestionsV2 {
      pet_type {
        cat_option {
          label
          description
          icon {
            icon_code
          }
        }
        dog_option {
          label
          description
          icon {
            icon_code
          }
        }
      }
    }
  }
`;

export const usePetTypeOptions = (): Option[] => {
  const petTypeOptions = useStaticQuery<CsPetTypeOptions>(query)
    .csPetAboutYourPetMainQuestionsV2.pet_type;

  return [
    {
      name: petTypeOptions.dog_option.label,
      value: petType_DOG,
      icon: unwrapSingleton(petTypeOptions.dog_option.icon)?.icon_code,
      description: petTypeOptions.dog_option.description,
    },
    {
      name: petTypeOptions.cat_option.label,
      value: petType_CAT,
      icon: unwrapSingleton(petTypeOptions.cat_option.icon)?.icon_code,
      description: petTypeOptions.cat_option.description,
    },
  ];
};
