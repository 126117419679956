import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import Layout from 'components/Layout';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import AboutYourPetsForm from 'forms/AboutYourPetsForm';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { CsHero } from 'types/contentStack';

const PAGE_LANDING_STEP = 1;

type AboutYourPetProps = {
  data: {
    csPetAboutYourPetV2: {
      meta_title: string;
      hero: CsHero;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csPetAboutYourPetV2 {
      meta_title
      hero {
        heading
        subheading
      }
    }
  }
`;

const AboutYourPet: React.FC<AboutYourPetProps> = ({
  data: {
    csPetAboutYourPetV2: { hero, meta_title },
  },
  location,
}) => {
  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.aboutYou);
  };

  const quote = useCurrentQuote();
  usePageTracking(meta_title);
  useCheckoutTracking(PAGE_LANDING_STEP, quote, false);

  const [assumptionsAgreement, updateAssumptionsAgreement] = useAssumptionsAgreement();

  if (!assumptionsAgreement.assumptionsInvalidDueToNavigatingToAboutYourPet) {
    updateAssumptionsAgreement({
      assumptionsInvalidDueToNavigatingToAboutYourPet: true,
    });
  }

  return (
    <LoadQuoteInProgressWrapper location={location}>
      <Layout
        pageTitle={PageTitle.AboutYourPet}
        currentStep={QuoteAndBuyStep.AboutYourPet}
        heading={hero.heading}
        subheading={hero.subheading}
        metaTitle={meta_title}>
        <Grid>
          <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6} mobile={4}>
            <AboutYourPetsForm moveNext={moveNext} />
          </GridItem>
        </Grid>
      </Layout>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYourPet;
