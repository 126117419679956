import RepeatableSection from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection';
import { Legend } from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection/styles';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';
import AdditionalFormSectionBanner from 'forms/AdditionalFormSectionBanner';

export const AboutYourPetFormRepeatableSection = styled(RepeatableSection)`
  && {
    margin-top: 0;
  }

  ${Legend} {
    margin-bottom: ${spacing(4)};

    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(6)};
    `}
  }
  h2 {
    ${fonts.headingMedium}
    margin-bottom: ${spacing(4)};

    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(6)};
    `}
  }
`;

export const AboutYourPetFormAdditionalSectionBanner = styled(
  AdditionalFormSectionBanner
)`
  padding-bottom: 0;
`;

export const ThreePetAddedBannerSection = styled(AdditionalFormSectionBanner)`
  padding-bottom: 0;
`;
